.navbar {
    width: 100%;
    min-height: 80px;
    z-index: 2000;
    padding-top: 10px;
}
.nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav .logo{
    display: flex;
    align-items: center;
}

.nav .logo h2 {
    text-transform: uppercase;
    color: var(--dark-color);
    font-size: 28px;
}

.nav .logo img{
    width: 100px;
    height:80px ;
}

.nav .nav-links{
    display:flex;
    gap: 25px;
    align-items: center;
}
.nav .nav-links li {
    list-style: none;
}

.navbar li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 17px;
    text-transform: capitalize;
    transition: color 0.3s;
    cursor: pointer;
}

.navbar li a:hover{
    color: var(--dark-color);
    text-decoration: none;
}

  
.hamburger {
    display: none;
  }
  
.fa{
    font-size: 28px;
    cursor: pointer;
}


@media (max-width: 992px) {
    .navbar {padding: 0;}
    .hamburger {
      display: flex;
    }
    .nav-links{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: -100%;
        width:70%;
        height: 80%;
        padding: 20px 0;
        background-color: var(--primary-color);
        transition: all 0.5s ease;
        text-align: center;
    }
    .nav-links.open {
        right: 0;
    }

    .nav-links li a{
        color: aliceblue;
    }
  }
  