body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: content-box;
}


:root {
  --primary-color: #56bdd1;
  --dark-color: rgb(46, 46, 46);
  --grey-color: rgb(86, 86, 86);
  --mTop:120px
}

body {
  scroll-behavior: smooth;
}

.container{
  width: 85%;
  margin: 0 auto;
}

.heading {
  font-size: 52px; 
  margin: 0 0 20px 0;
  color: var(--dark-color);
}


@media  (max-width:767px) {
   .heading {
      font-size: 40px
  }}