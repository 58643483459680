.our-services{
    margin-top: var(--mTop);
    text-align: center;
}

.our-services p.head{
    color: var(--primary-color);
    font-size: 19px;
    font-weight: 500;

}

.our-services .services{
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    align-items: center;
    justify-content: space-between;
}


.services .service {
    width: 30%;
    padding: 0 10px;
    height:400px;
    background-color: white;
    box-shadow: 2px 3px 3px rgb(175, 175, 175);
    border: 1px solid rgb(239, 239, 239);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.services .service img{
    width: 160px;
    height: 150px;
}
.services .service p{
    line-height: 1.8;
    color: var(--grey-color);
    width: 75%;
    font-size: 18px;
}

@media (max-width: 992px) {
    .services{
        gap: 20px;
    }
    .services .service {
        width: 45%;
    }
}

@media  (max-width:767px) {
    .services{
        flex-direction: column
    }

    .services .service {
        width: 90%
    }
}