.whatsapp {
    display: flex;
    gap: 10px;
    align-items: center;
}

.whatsapp .btn {
    font-size: 18px;
    padding:  13px 11px;
    width: 150px;
    border: 0;
    border-radius: 8px;
}

.whatsapp a{
    text-decoration: none;
    color: white;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

@media (max-width: 550px) {
    .whatsapp .btn-whatsapp {
        width:180px}
}