.footer{
    text-align: center;
    background-color: var(--primary-color);
    text-align: center;
    color: white;
    margin-top: 50px;
    padding: 60px 0;
}

.footer P{
    margin-top: 10px;
    font-size: 18px;
}
